
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import { ShowDialog, CodeList } from "@/helper";

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
  setup() {
    const bkcolor = ref("white");
    const items = ["General Inquiry", "RISN", "CBDC", "Roxe One"];
    const reasonList1 = [
      "Issuing your own digital currency",
      "Deploy your own CBDC",
      "Learn about CBDC",
      "Integrate with Roxe global network",
      "Interested in a CBDC pilot",
      "Other",
    ];
    const reasonList = computed(() => {
      if (formIndex.value == 2) {
        return [
          "Issuing your own digital currency",
          "Deploy your own CBDC",
          "Learn about CBDC",
          "Integrate with Roxe global network",
          "Interested in a CBDC pilot",
          "Other",
        ];
      } else {
        return [
          "Sell my eGiftCards",
          "Integrate loyalty rewards",
          "Accept Roxe Pay QR code payments",
          "Other",
        ];
      }
    });
    const orgList1 = [
      "Bank",
      "Digital Bank",
      "Credit Union",
      "Exchange",
      "Service Bureau",
      "Payment Remittance Company",
      "Business",
      "Other",
    ];
    const orgList = computed(() => {
      if (formIndex.value == 1) {
        return [
          "Bank",
          "Digital Bank",
          "Credit Union",
          "Exchange",
          "Service Bureau",
          "Payment Remittance Company",
          "Business",
          "Other",
        ];
      } else if (formIndex.value == 2) {
        return ["Bank", "Central Bank", "Government", "Other"];
      } else if (formIndex.value == 3) {
        return [
          "Food",
          "Online shopping",
          "Beauty & spa",
          "Health & wellbeing",
          "Pet supplies",
          "Home & office",
          "Entertainment",
          "Clothing & accessories",
          "Travel",
          "Music",
          "Gas & convenience",
          "Automotive",
          "Other",
        ];
      }
      return [];
    });
    const formIndex = computed(() => {
      const val = data.inster || "General Inquiry";
      return items.indexOf(val);
    });
    const data = reactive({
      inster: "",
      first: {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      },
      last: {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      },
      email: {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      },
      countryId: "",
      region: "",
      organization: "",
      orgType: "",
      country: "+1",
      phone: {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      },
      website: "",
      linkin: "",
      message: "",
      job: "",
      reason: "",
    });
    const resetdata = () => {
      data.inster = "";
      data.first = {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      };
      data.last = {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      };
      data.email = {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      };
      data.country = "+1";
      data.countryId = "";
      data.region = "";
      data.organization = "";
      data.orgType = "";
      data.orgType = "";
      data.phone = {
        value: "",
        notnull: true,
        error: false,
        msg: "can not null",
      };
      data.website = "";
      data.linkin = "";
      data.message = "";
    };
    const checkdata = () => {
      let reg = new RegExp("^(\\w+\\.*\\w+)*@(\\w+\\.*\\w+)*$");
      if (reg.test(data.email.value) == false) {
        data.email.msg = "E-mail address is invalid";
        data.email.error = true;
        return true;
      }
      data.email.error = false;

      reg = new RegExp("^(\\d+)$");
      if (reg.test(data.phone.value) == false) {
        data.phone.msg = "Phone number is invalid";
        data.phone.error = true;
        return true;
      }
      data.phone.error = false;
      // first,last
      if (data.first.value == "") {
        data.first.msg = "can not null";
        data.first.error = true;
        return true;
      }
      data.first.error = false;
      if (data.last.value == "") {
        data.last.msg = "can not null";
        data.last.error = true;
        return true;
      }
      data.last.error = false;
      return false;
    };
    const _load_script = function (url: string, callback: any) {
      let head: any = document.querySelector("head");
      let script: any = document.createElement("script");
      let r = false;
      script.type = "text/javascript";
      script.src = url;
      if (callback) {
        script.onload = script.onreadystatechange = function () {
          if (!r && (!this.readyState || this.readyState == "complete")) {
            r = true;
            callback();
          }
        };
      }
      head.appendChild(script);
    };
    const win: any = window;
    win._show_thank_you = () => {
      ShowDialog("Thanks for signing up!");
    };
    win._show_error = (id: string, message: string) => {
      ShowDialog(message);
    };
    _load_script(
      "//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js",
      function () {
        win._form_serialize = win.serialize;
        console.log(win._form_serialize);
      }
    );
    let loading = false;
    const forms = ["_form_16_", "_form_12_", "_form_14_", "_form_15_"];
    const handsubmit = () => {
      if (checkdata()) {
        return;
      }
      if (loading) return;
      loading = true;
      let form_name = forms[formIndex.value];
      var serialized = win
        ._form_serialize(document.getElementById(form_name))
        .replace(/%0A/g, "\\n");
      console.log(`json:${serialized}`);
      _load_script(
        "https://apifiny.activehosted.com/proc.php?" + serialized + "&jsonp=true",
        () => {
          //reset date
          resetdata();
          loading = false;
        }
      );
    };
    const phonenum = computed(() => {
      return `${data.country}${data.phone.value}`;
    });
    const handfirst = (first = true) => {
      if (first) {
        if (data.first.value == "") {
          data.first.msg = "can not null";
          data.first.error = true;
          return true;
        }
        data.first.error = false;
      } else {
        if (data.last.value == "") {
          data.last.msg = "can not null";
          data.last.error = true;
          return true;
        }
        data.last.error = false;
      }
    };
    const handlemail = () => {
      let reg = new RegExp("^(\\w+\\.*\\w+)*@(\\w+\\.*\\w+)*$");
      if (reg.test(data.email.value) == false) {
        data.email.msg = "E-mail address is invalid";
        data.email.error = true;
        return true;
      }
      data.email.error = false;
    };
    const PhList = computed(() => {
      const res: Array<any> = [];
      Object.keys(CodeList).forEach((key) => {
        (CodeList as any)[key].map((it: any) => {
          res.push({
            title: it.english_name,
            key: it.phone_code,
          });
        });
      });
      return res;
    });
    const handcmd = (nn: string) => {
      data.country = `+${nn}`;
    };

    return {
      handcmd,
      handfirst,
      handlemail,
      PhList,
      reasonList,
      phonenum,
      orgList,
      formIndex,
      handsubmit,
      data,
      bkcolor,
      items,
    };
  },
});
